import Close from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { i18n } from 'i18next';
import * as moment from 'moment';
import 'moment-timezone';
import React, { useEffect, useState } from 'react';

import { COLOR_LINK, COLOR_WHITE, GRAY_COLORS } from '../../../../constants/colors';

import { useStore } from '../../../../hooks/useStore';
import { EMPTY_USER, User } from '../../../../models/User';
import Button from '../../../buttons/Button';
import WarningModal from '../../../modal/WarningModal';
import Flex from '../../../utils/flex/Flex';
import FlexRowStart from '../../../utils/flex/FlexRowStart';
import IconByAction from '../../auditLogs/IconByAction';
import CreateTeamModal from '../CreateTeamModal';
import TeamStatisticsItem from './teamStatistics/TeamStatisticsItem';
import TeamUserRow from './teamUserRow/TeamUserRow';

interface TeamMemberProps {
  i18n: i18n;
  users: User[];
  setSelectedUser?: (a: User) => void;
  setIsRemoveUserFromTeamModalOpen?: (a: boolean) => void;
}

const TeamMembers = ({ i18n, users, setSelectedUser, setIsRemoveUserFromTeamModalOpen }: TeamMemberProps) => {
  let onRemoveClick: any = undefined;
  if (setSelectedUser && setIsRemoveUserFromTeamModalOpen) {
    onRemoveClick = (user: User) => {
      setSelectedUser?.(user);
      setIsRemoveUserFromTeamModalOpen?.(true);
    };
  }
  return (
    <>
      <Typography variant={'body2'} sx={{ color: GRAY_COLORS.GRAY_500, marginTop: '16px' }}>
        {i18n.t('teamList.details.members')}
      </Typography>
      {users?.map(user => <TeamUserRow key={`team-user-${user.id}`} teamUser={user} onRemoveClick={onRemoveClick} />)}
    </>
  );
};

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  '&': {
    cursor: 'pointer',
  },
  '& a': {
    color: COLOR_LINK,
  },
  '& .tooltip-text': {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '18px',
    padding: '16px',
    fontFamily: 'Poppins, serif',
    color: COLOR_WHITE,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    borderRadius: '8px',
  },
}));

const TeamDetailsCard = ({ onTeamAuditLogNavigate }: { onTeamAuditLogNavigate?: Function }) => {
  const {
    teamStore: { selectedTeam, clearSelectedTeam, updateTeam, getTeamById, removeTeam },
    localizationStore: { i18next: i18n },
    auditLogsStore: { currentAuditLog },
  } = useStore();
  const { createdAt, name, createdBy, users, id, totalChatViolations, totalChats, totalPrompts } = selectedTeam;

  const [selectedUser, setSelectedUser] = useState(EMPTY_USER);
  const [isRemoveUserFromTeamModalOpen, setIsRemoveUserFromTeamModalOpen] = useState(false);
  const [isEditTeamModalOpen, setIsEditTeamModalOpen] = useState(false);
  const [isDeleteTeamModalOpen, setIsDeleteTeamModalOpen] = useState(false);

  useEffect(() => {
    (async () => {
      if (currentAuditLog?.entityId) {
        await getTeamById(currentAuditLog.entityId);
      }
    })();
  }, [currentAuditLog?.entityId]);

  const handleRemoveUser = async () => {
    try {
      await updateTeam(id, { users: users.filter(user => user.id !== selectedUser.id).map(user => ({ id: user.id })) });
    } catch (e) {
      console.log('e ------------------->> ', e);
    } finally {
      setIsRemoveUserFromTeamModalOpen(false);
    }
  };

  const handleRemoveTeam = async () => {
    try {
      await removeTeam(id);
    } catch (e) {
      console.log('e ------------------->> ', e);
    } finally {
      setIsDeleteTeamModalOpen(false);
    }
  };

  if (currentAuditLog?.entityId) {
    return (
      <Stack sx={{ padding: '24px' }}>
        <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
          <Typography variant="h6" sx={{ fontSize: '18px', fontWeight: 700 }}>
            {name}
          </Typography>
          <IconButton size={'small'} onClick={clearSelectedTeam}>
            <Close />
          </IconButton>
        </Box>
        {currentAuditLog?.action && (
          <FlexRowStart sx={{ gap: '12px', marginBottom: '24px' }}>
            <IconByAction action={currentAuditLog?.action} />
            <Typography variant="subtitle2" sx={{ color: GRAY_COLORS.GRAY_6 }}>
              {`This chat was ${currentAuditLog?.action}d by ${currentAuditLog.userName} on ${moment
                .tz(currentAuditLog?.createdAt, 'EST')
                .format('MMM DD, YYYY')}`}
            </Typography>
          </FlexRowStart>
        )}
        <TeamMembers i18n={i18n} users={users} />
      </Stack>
    );
  }

  const tooltipContent = (
    <div
      className={'tooltip-text'}
      onClick={() => {
        onTeamAuditLogNavigate && onTeamAuditLogNavigate(selectedTeam);
      }}
    >
      {i18n.t('teamsList.details.tooltip')} <br />
      <a href="#">{i18n.t('adminPortal.tabs.logs.action')}</a>.
    </div>
  );

  return (
    <Stack sx={{ padding: '24px' }}>
      <Flex sx={{ width: '100%', gap: '16px' }}>
        <Typography variant={'body2'} sx={{ fontSize: '12px', color: GRAY_COLORS.GRAY_5 }}>
          {i18n.t('teamList.details.creator')}: {createdBy}
        </Typography>
        <Typography variant={'body2'} sx={{ fontSize: '12px', color: GRAY_COLORS.GRAY_5 }}>
          {i18n.t('teamList.details.date')}: {new Date(createdAt).toDateString()}
        </Typography>
        <IconButton onClick={clearSelectedTeam} size={'small'} sx={{ marginLeft: 'auto' }}>
          <Close fontSize={'small'} />
        </IconButton>
      </Flex>
      <Typography variant={'h5'} sx={{ fontSize: '18px', fontWeight: 600, marginTop: '16px' }}>
        {name}
      </Typography>
      <Flex sx={{ gap: '16px', marginTop: '8px' }}>
        <Button
          variant={'outlined'}
          startIcon={<DeleteOutlineIcon />}
          onClick={() => setIsDeleteTeamModalOpen(true)}
          sx={{
            width: 'fit-content',
            borderColor: `${GRAY_COLORS.GRAY_2} !important`,
            color: `${GRAY_COLORS.GRAY_9} !important`,
          }}
          id="Delete-Team"
        >
          {i18n.t('teamsList.button.delete')}
        </Button>
        <Button
          variant={'outlined'}
          startIcon={<EditOutlinedIcon />}
          sx={{
            width: 'fit-content',
            borderColor: `${GRAY_COLORS.GRAY_2} !important`,
            color: `${GRAY_COLORS.GRAY_9} !important`,
          }}
          id="Edit-Team"
          onClick={() => setIsEditTeamModalOpen(true)}
        >
          {i18n.t('teamsList.button.edit')}
        </Button>
      </Flex>
      <Flex sx={{ alignItems: 'center', justifyContent: 'space-between', marginTop: '40px' }}>
        <BootstrapTooltip title={tooltipContent}>
          <div>
            <TeamStatisticsItem
              Icon={LightbulbIcon}
              title={i18n.t('teamList.details.prompts')}
              amount={totalPrompts || 0}
            />
          </div>
        </BootstrapTooltip>

        <BootstrapTooltip title={tooltipContent}>
          <div>
            <TeamStatisticsItem
              Icon={QuestionAnswerIcon}
              title={i18n.t('teamList.details.chats')}
              amount={totalChats || 0}
            />
          </div>
        </BootstrapTooltip>
        <BootstrapTooltip title={tooltipContent}>
          <div>
            <TeamStatisticsItem
              Icon={ReportProblemIcon}
              title={i18n.t('teamList.details.violations')}
              amount={totalChatViolations || 0}
            />
          </div>
        </BootstrapTooltip>
      </Flex>
      <TeamMembers
        i18n={i18n}
        users={users}
        setSelectedUser={setSelectedUser}
        setIsRemoveUserFromTeamModalOpen={setIsRemoveUserFromTeamModalOpen}
      />
      <WarningModal
        isOpen={isRemoveUserFromTeamModalOpen}
        onClose={() => setIsRemoveUserFromTeamModalOpen(false)}
        heading={i18n.t('removeTeamMember.heading')}
        description={i18n.t('removeTeamMember.message', { fullName: selectedUser.fullName })}
        confirmButton={
          <Button
            onClick={handleRemoveUser}
            variant={'contained'}
            sx={{ width: 'fit-content', height: '40px !important' }}
          >
            {i18n.t('common.remove.action')}
          </Button>
        }
        icon={<NotificationImportantIcon sx={{ fill: GRAY_COLORS.GRAY_5 }} />}
      />
      <WarningModal
        isOpen={isDeleteTeamModalOpen}
        onClose={() => setIsDeleteTeamModalOpen(false)}
        heading={i18n.t('deleteTeam.heading')}
        description={i18n.t('deleteTeam.message', { fullName: selectedTeam.name })}
        confirmButton={
          <Button
            onClick={handleRemoveTeam}
            variant={'contained'}
            sx={{ width: 'fit-content', height: '40px !important' }}
            id="Delete-Btn"
          >
            {i18n.t('common.delete.action')}
          </Button>
        }
        icon={<DeleteOutlineIcon sx={{ fill: GRAY_COLORS.GRAY_5 }} />}
      />
      <CreateTeamModal
        isOpen={isEditTeamModalOpen}
        onClose={() => setIsEditTeamModalOpen(false)}
        isEdit
        team={selectedTeam}
      />
    </Stack>
  );
};

export default TeamDetailsCard;
