import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { Paths } from '../constants/routes';

import {
  authenticationAndSidebarFilePreviewWrapper,
  authenticationAndSidebarWrapper,
  loginWrapper,
  RestrictedAdminsPageComponent,
} from './RestrictedPages';
import AuthenticationProvider from '../providers/AuthenticationProvider';
import LoginPage from '../pages/LoginPage';
import HistoryPage from '../pages/HistoryPage/HistoryPage';
import KnowledgeBasePage from '../pages/ConversationPage/knowledgeBase/KnowledgeBasePage';
import ProfilePage from '../pages/ProfilePage';
import MemberSignUpPage from '../pages/SignUpPage/MemberSignUp/MemberSignUpPage';
import KnowledgeBaseFilePreview from '../pages/ConversationPage/knowledgeBase/KnowledgeBaseFilePreview';
import ModelsPage from '../pages/AdminPortalPage/ModelsPage/ModelsPage';
import CompliancePage from '../pages/AdminPortalPage/CompliancePage';
import IntegrationsPage from '../pages/AdminPortalPage/IntegrationsPage';
import InsightsPage from '../pages/AdminPortalPage/InsightsPage';
import TeamsPage from '../pages/AdminPortalPage/TeamsPage';
import UsersPage from '../pages/AdminPortalPage/UsersPage';
import AuditLogPage from '../pages/AdminPortalPage/AuditLogPage';
import PlaygroundPage from '../pages/AdminPortalPage/PlaygroundPage';
import CommingSoonPage from '../pages/AdminPortalPage/CommingSoonPage';
import ModelEvaluationPage from '../pages/AdminPortalPage/ModelEvaluationPage';

const AppRoutes = () => {
  return (
    <Router>
      <AuthenticationProvider>
        <Routes>
          <Route path={Paths.MEMBER_SIGN_UP} element={<MemberSignUpPage />} />
          <Route path={Paths.LOGIN} element={loginWrapper(LoginPage)} />
          <Route path={Paths.INIT} element={loginWrapper(LoginPage)} />
          <Route path={Paths.HISTORY} element={authenticationAndSidebarWrapper(HistoryPage)} />
          <Route path={Paths.KNOWLEDGE_BASE} element={authenticationAndSidebarWrapper(KnowledgeBasePage)} />
          <Route
            path={`${Paths.KNOWLEDGE_BASE}/team/:id`}
            element={authenticationAndSidebarWrapper(KnowledgeBasePage)}
          />
          <Route
            path={`${Paths.KNOWLEDGE_BASE}/:fileId`}
            element={authenticationAndSidebarFilePreviewWrapper(KnowledgeBaseFilePreview)}
          />
          <Route path={Paths.PROFILE} element={authenticationAndSidebarWrapper(ProfilePage)} />
          <Route path={Paths.ADMIN_PORTAL} element={RestrictedAdminsPageComponent(InsightsPage)} />
          <Route
            path={`${Paths.ADMIN_PORTAL}/teams`}
            element={RestrictedAdminsPageComponent(() => (
              <TeamsPage />
            ))}
          />
          <Route
            path={`${Paths.ADMIN_PORTAL}/users`}
            element={RestrictedAdminsPageComponent(() => (
              <UsersPage />
            ))}
          />
          <Route
            path={`${Paths.ADMIN_PORTAL}/audit-log`}
            element={RestrictedAdminsPageComponent(() => (
              <AuditLogPage />
            ))}
          />
          <Route path={`${Paths.ADMIN_PORTAL}/models`} element={RestrictedAdminsPageComponent(ModelsPage)} />
          <Route
            path={`${Paths.ADMIN_PORTAL}/compliance`}
            element={RestrictedAdminsPageComponent(() => (
              <CompliancePage />
            ))}
          />
          <Route
            path={`${Paths.ADMIN_PORTAL}/integrations`}
            element={RestrictedAdminsPageComponent(() => (
              <IntegrationsPage />
            ))}
          />
          <Route path={`${Paths.ADMIN_PORTAL}/playground`} element={RestrictedAdminsPageComponent(PlaygroundPage)} />
          <Route
            path={`${Paths.ADMIN_PORTAL}/model-evaluation`}
            element={RestrictedAdminsPageComponent(ModelEvaluationPage)}
          />
          <Route
            path={`${Paths.ADMIN_PORTAL}/security-policies`}
            element={RestrictedAdminsPageComponent(CommingSoonPage)}
          />
          <Route path={`${Paths.ADMIN_PORTAL}/warnings`} element={RestrictedAdminsPageComponent(CommingSoonPage)} />
          <Route path={`${Paths.ADMIN_PORTAL}/help-center`} element={RestrictedAdminsPageComponent(CommingSoonPage)} />
        </Routes>
      </AuthenticationProvider>
    </Router>
  );
};

export default AppRoutes;
