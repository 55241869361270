import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import { Dayjs } from 'dayjs';
import { observer } from 'mobx-react';
import * as moment from 'moment';
import 'moment-timezone';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { i18n } from 'i18next';

import { COLOR_PRIMARY, COLOR_WHITE } from '../../constants/colors';
import { LocalStorageKeys } from '../../constants/localStorageKeys';
import { SIZES_SMALL } from '../../constants/sizes';

import ConversationDetailPage from '../../components/adminPortal/auditLogs/ConversationDetailPage';
import FeedbackDetailPage from '../../components/adminPortal/auditLogs/FeedbackDetailPage';
import { getExcelData } from '../../components/adminPortal/auditLogs/excelData';
import FiltersSection from '../../components/adminPortal/auditLogs/FiltersSection';
import PromptDetailPage from '../../components/adminPortal/auditLogs/PromptDetailPage';
import { getTableHeader } from '../../components/adminPortal/auditLogs/tableHeaders';
import TeamDetailsCard from '../../components/adminPortal/teams/teamCards/TeamDetailsCard';
import UserDetailPanel from '../../components/adminPortal/users/UserDetailPanel';
import ExportExcel from '../../components/exportFile/ExportExcel';
import ItemsList from '../../components/itemsList/ItemsList';
import { LoadingSpinner } from '../../components/spinner/LoadingSpinner';
import Status from '../../components/status/StatusLabel';
import UserAvatar from '../../components/UserAvatar/UserAvatar';
import FlexRowStart from '../../components/utils/flex/FlexRowStart';
import { useStore } from '../../hooks/useStore';
import { AuditLog, MetaDataModel } from '../../models/AuditLog';
import { ItemTableModel } from '../../models/ItemProps';
import { Team } from '../../models/Team';
import SupportedAiProviderLogo from '../../components/SupportedProviderLogo/SupportedAiProviderLogo';
import ModelEvaluationDetailPage from '../../components/adminPortal/auditLogs/ModelEvaluationDetailPage';

const PageContainer = styled(Box)`
  align-items: center;
  height: calc(100vh - 176px);
  margin: 0;
  gap: 16px;
  border-radius: 8px;

  overflow: auto;

  @media screen and (max-width: ${SIZES_SMALL}) {
    overflow-x: hidden;
  }
`;

const capitalizeFirst = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const getTableData = (auditLogs: AuditLog[], onRowClick: (item: AuditLog) => void, i18n: i18n) => {
  const tableData = auditLogs?.map((item: AuditLog) => {
    let entityType = item?.entityType || '';
    if (entityType && item?.entityType === 'conversation') {
      entityType = 'Deleted Chat';
    }

    return {
      ...item,
      fullName: (
        <FlexRowStart onClick={() => onRowClick(item)} sx={{ cursor: 'pointer', gap: '12px' }}>
          <UserAvatar user={{ id: item.userId, fullName: item.userName }} size={24} />
          {item.userName || ''}
        </FlexRowStart>
      ),
      status: (item?.status && <Status type={item.status} />) || '',
      type:
        (entityType &&
          (item.action
            ? capitalizeFirst(`${entityType} ${item.action === 'hallucination' ? item.action : `${item.action}d`}`)
            : capitalizeFirst(entityType))) ||
        '',
      source: item?.additionalInformation?.chat?.providerName ? (
        <SupportedAiProviderLogo provider={item?.additionalInformation?.chat?.providerName} size={24} />
      ) : (
        ''
      ),
      createdAt: (item?.createdAt && moment.tz(item?.createdAt, 'EST').format('MM.DD.YYYY \n hh:mm A')) || '',
      actions: (
        <FlexRowStart onClick={() => onRowClick(item)} sx={{ cursor: 'pointer', gap: '8px' }}>
          <RemoveRedEyeOutlinedIcon sx={{ color: COLOR_PRIMARY }} />
          {i18n.t('menuPopUp.view.action')}
        </FlexRowStart>
      ),
    };
  });
  return tableData;
};

const ListContainer = styled(Stack)`
  border-radius: 8px;
  background-color: ${COLOR_WHITE};
  box-shadow:
    0 2px 6px 0 rgba(0, 0, 0, 0.1),
    0 1px 4px 0 rgba(0, 0, 0, 0.05);

  height: 100%;
  overflow: hidden;
`;

const AuditLogPage = ({ activeTab }: { activeTab?: number }) => {
  const {
    localizationStore: { i18next: i18n },
    auditLogsStore: {
      getDataToExportLogsWithFilters,
      getPaginatedHistoryLogsWithFilters,
      auditLogs,
      setSelectedItem,
      selectedItem,
    },
    teamStore: { teams, getTeamsByUserOrganization },
    userStore: { getUsersByUserOrganization, users },
    filterStore: { searchValueFilter },
  } = useStore();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedEvents, setSelectedEvents] = useState<string[]>([]);
  const [selectedTeams, setSelectedTeams] = useState<string[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState<MetaDataModel | null>(null);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);

  const defaultTeamId = localStorage.getItem(LocalStorageKeys.auditLogTeamName);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      await getTeamsByUserOrganization();
      await getUsersByUserOrganization();
      await clearFilters();

      if (defaultTeamId) {
        localStorage.removeItem(LocalStorageKeys.auditLogTeamName);

        setSelectedTeams([defaultTeamId]);
      }

      setIsLoading(false);
    })();

    return () => {
      setSelectedItem(null, null, null);
    };
  }, []);

  const clearFilters = async () => {
    setSelectedEvents([]);
    setSelectedTeams([]);
    setSelectedStatuses([]);
    setSelectedUsers([]);
    setStartDate(null);
    setEndDate(null);
    setPage(1);
  };

  useEffect(() => {
    (async () => {
      await applyFilters();
    })();
  }, [
    activeTab,
    selectedEvents,
    selectedTeams,
    selectedUsers,
    searchValueFilter,
    startDate,
    endDate,
    page,
    selectedStatuses,
  ]);

  const applyFilters = async () => {
    const teamsToAdd = teams?.filter((team: Team) => selectedTeams?.includes(team.name)).map(item => item?.id);
    const usersToAdd = users?.filter(user => selectedUsers?.includes(user.fullName)).map(item => item?.id);
    const response = await getPaginatedHistoryLogsWithFilters({
      page,
      userId: usersToAdd,
      teamId: teamsToAdd,
      entityType: selectedEvents,
      status: selectedStatuses,
      search: searchValueFilter,
      startDate: startDate ? startDate.format('YYYY-MM-DDT00:00:00') : null,
      endDate: endDate ? endDate.format('YYYY-MM-DDT23:59:59') : null,
    });

    if (response?.meta) {
      setMeta(response.meta);
    }
  };

  const getDataToExport = async () => {
    const teamsToAdd = teams?.filter((team: Team) => selectedTeams?.includes(team.name)).map(item => item?.id);
    const usersToAdd = users?.filter(user => selectedUsers?.includes(user.fullName)).map(item => item?.id);

    return getDataToExportLogsWithFilters({
      page: 1,
      userId: usersToAdd,
      teamId: teamsToAdd,
      entityType: selectedEvents,
      status: selectedStatuses,
      search: searchValueFilter,
      startDate: startDate ? startDate.format('YYYY-MM-DDT00:00:00') : null,
      endDate: endDate ? endDate.format('YYYY-MM-DDT23:59:59') : null,
      limit: meta?.totalItems,
    });
  };

  const headerCells = getTableHeader({ i18n });

  const dataToExportExcel = getExcelData({ i18n, items: auditLogs });

  const onRowClick = (item: ItemTableModel) => {
    if (item?.entityType && item.entityId) {
      setSelectedItem(item.entityType, item.entityId, item);
    }
  };

  const closeModal = () => {
    setSelectedItem(null, null, null);
  };

  if (isLoading) {
    return <LoadingSpinner contained />;
  }

  return (
    <PageContainer sx={selectedItem?.entityType ? { display: 'flex', alignItems: 'flex-start' } : {}}>
      <ListContainer sx={{ width: selectedItem?.entityType ? '60%' : 'auto', height: '100%' }}>
        <ItemsList
          widthPagination={true}
          page={page}
          setPage={setPage}
          totalPages={meta?.totalPages}
          headerCells={headerCells}
          tableData={getTableData(auditLogs, onRowClick, i18n) || []}
          onRowClick={onRowClick}
          clearFilters={clearFilters}
          pageTitle={i18n.t('adminPortal.tabs.logs.action')}
          selectedItem={selectedItem}
          additionalRow={
            <FiltersSection
              selectedTeams={selectedTeams}
              setSelectedTeams={setSelectedTeams}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              selectedEvents={selectedEvents}
              setSelectedEvents={setSelectedEvents}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              selectedStatuses={selectedStatuses}
              setSelectedStatuses={setSelectedStatuses}
            />
          }
          customRight={
            <ExportExcel
              dataToExportExcel={dataToExportExcel}
              fileName={'audit-logs-list'}
              sx={{ marginLeft: '16px' }}
              exportAdditionalData={getDataToExport}
            />
          }
        />
      </ListContainer>
      {selectedItem?.entityType && (
        <ListContainer sx={{ width: '40%' }}>
          {selectedItem?.entityType === 'user' && (
            <UserDetailPanel userId={selectedItem?.entityId} onCloseDetailPanel={closeModal} />
          )}
          {selectedItem?.entityType === 'team' && <TeamDetailsCard />}
          {(selectedItem?.entityType === 'chat' || selectedItem?.entityType === 'conversation') && (
            <ConversationDetailPage id={selectedItem.entityId} closeModal={closeModal} />
          )}
          {selectedItem?.entityType === 'response' && (
            <FeedbackDetailPage id={selectedItem.entityId} closeModal={closeModal} />
          )}
          {selectedItem?.entityType === 'prompt' && (
            <PromptDetailPage id={selectedItem?.entityId} closeModal={closeModal} />
          )}
          {selectedItem?.entityType === 'modelEvaluation' && (
            <ModelEvaluationDetailPage id={selectedItem?.entityId} closeModal={closeModal} />
          )}
        </ListContainer>
      )}
    </PageContainer>
  );
};

export default observer(AuditLogPage);
