import { makeAutoObservable, runInAction } from 'mobx';
import autoBind from 'auto-bind';

import { AuditLogsApi } from '../api/AuditLogsApi';
import { AuditLog, CreateAuditLogDto, FullSearchModel, INITIAL_AUDIT_LOG } from '../models/AuditLog';
import { UserApi } from '../api/UserApi';
import { ConversationApi } from '../api/ConversationApi';

export class AuditLogsStore {
  auditLogsApi: AuditLogsApi;
  userApi: UserApi;
  conversationApi: ConversationApi;
  auditLogs: Array<AuditLog> = [INITIAL_AUDIT_LOG];
  selectedItem: { entityType: string | null; entityId: string | null; item?: any } = {
    entityType: null,
    entityId: null,
  };
  currentAuditLog: any;

  constructor(auditLogsApi: AuditLogsApi, userApi: UserApi, conversationApi: ConversationApi) {
    this.auditLogsApi = auditLogsApi;
    this.userApi = userApi;
    this.conversationApi = conversationApi;
    makeAutoObservable(this);
    autoBind(this);
  }

  async getHistoryLogsByType(type: string) {
    const logs = await this.auditLogsApi.getHistoryLogsByType(type);

    runInAction(() => {
      this.auditLogs = logs;
    });
  }

  async getPaginatedHistoryLogsWithFilters(data: FullSearchModel) {
    const logs = await this.auditLogsApi.getPaginatedHistoryLogsWithFilters(data);

    runInAction(() => {
      this.auditLogs = logs?.data;
    });

    return logs;
  }

  async getDataToExportLogsWithFilters(data: FullSearchModel) {
    const logs = await this.auditLogsApi.getPaginatedHistoryLogsWithFilters(data);

    return logs?.data;
  }

  async createAuditLog(auditLog: CreateAuditLogDto) {
    await this.auditLogsApi.createAuditLog(auditLog);
  }

  async setSelectedItem(entityType: string | null, entityId: string | null, item: any) {
    let sharedToUsers;
    if (item?.additionalInformation?.sharedTo?.length) {
      sharedToUsers = await Promise.all(
        item?.additionalInformation?.sharedTo?.map(async (userId: string) => {
          const user = await this.userApi.getUserDataById(userId);
          return user;
        })
      );
    }

    runInAction(() => {
      const currentAuditLog = sharedToUsers?.length
        ? { ...item, additionalInformation: { sharedTo: sharedToUsers } }
        : item;
      this.selectedItem = {
        entityType,
        entityId,
      };
      this.currentAuditLog = currentAuditLog;
    });
  }
}
